import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Wherefore, men are free according to the flesh; and all things are given them
which are expedient unto man. And they are free to choose liberty and eternal
life, through the great Mediator of all men, or to choose captivity and death,
according to the captivity and power of the devil; for he seeketh that all men
might be miserable like unto himself. 2 Nephi 2:27`}</p>
    </blockquote>
    <p>{`We are free to make our own choices and that freedom must be guarded at all
costs. We must not give that freedom to others who would wield our emotions
against us like a weapon. That trap called co-dependency which is its own form
of imprisonment with bars made of guilt, fear, and helplessness. We must not
give that freedom to the empty promises of lust, chemicals, pleasures and
worldly addictions. We must not give that freedom to our pride, acting with the
intention that we will be risen in someone else's mind or "accepted". We must
not give that freedom to comfort which would ultimately corrode us. Freedom must
be defended. We need to guard that freedom from the imprisonment of our own fear
and emotions. We must defend that freedom from false conclusions about our
identity that such and such is "just the way I am". Free will must be defended
by personal righteousness.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      